<template>
  <div class="d-flex flex-column align-center justify-center h-full">


    <v-container fill-height>
        <v-snackbar
        v-if="showError"
          v-model="errorSignup"
          :multi-line="multiLine"
        >
              {{ errorMessageSignup }}
              <br>
              {{errors[0]}}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="setErrorSignin()"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      <v-row>
        <v-col align-self="center">
          <v-card
            elevation="0"
            max-width="500"
            rounded="sm"
            class="mx-auto rounded-xl"
          >
          <!-- <div class="pt-2 text-center" style="color:red" v-if="errorSignup || errors.length">
              {{ errorMessageSignup }}
              <br>
              {{errors[0]}}
           </div> -->
            <h2 class="text-capitalize text-center pa-5 pb-0">Sign Up With Email </h2>
            <v-card-text class="pa-5">
              <v-text-field
                v-model.trim="userData.email"
                :rules="[rules.required]"
                :suffix="emailDomain"
                required
                type="email"
                label="Email"
                outlined
              >
              </v-text-field>
              <span class="text-left black--text">We'll send your verification email here.</span>

              <v-text-field
                class="mt-5"
                v-model.trim="userData.name"
                :rules="[rules.required, rules.name]"
                label="Name"
                outlined
              >
              </v-text-field>

              <v-text-field
                v-model.trim="userData.password"
                label="Password"
                outlined
                hint="At least 8 characters"
                :rules="[rules.required, rules.password]"
                required
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              >
              </v-text-field>

              <!-- <div class="text-center" style="color:red" v-if="passwordMatchErrorStatus">
                {{ passwordMatchError }}
              </div> -->
              <v-text-field
                v-model.trim="userData.password_confirmation"
                label="Confirm Password"
                outlined
                required
                hint="At least 8 characters"
                :rules="[rules.required, rules.password, rules.confirmPassword]"
                @click:append="showConfirmPassword = !showConfirmPassword"
                :type="showConfirmPassword ? 'text' : 'password'"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              >
              </v-text-field>
<!-- 
              <div class="text-center" style="color:red" v-if="passwordMatchErrorStatus">
                {{ passwordMatchError }}
              </div> -->

              <v-btn
                :loading="$store.state.auth.loading"
                @click="Register"
                depressed
                block
                class="primary"
                height="50"
                rounded
                :disabled="
                  !userData.name ||
                  !userData.email ||
                  !userData.password ||
                  !userData.password_confirmation
                "
              >
                Sign Up
              </v-btn>
            </v-card-text>
          </v-card>
          <SocialLogin :page="'signUp'" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SocialLogin from "@/components/Utils/SocialLogin";
import { mapGetters } from "vuex";

export default {
   components: {
    SocialLogin,
  },
  data() {
    return {
      showError:true,
      passwordErrorMessage:"A password must be eight characters including one uppercase letter,one lowercase letter, one special character and alphanumeric characters.",
      emailDomain: "",
      passwordRules: [
				{ message:'One lowercase letter required.', regex:/[a-z]+/ },
				{ message:"One uppercase letter required.",  regex:/[A-Z]+/ },
				{ message:"8 characters minimum.", regex:/.{8,}/ },
				{ message:"One number required.", regex:/[0-9]+/ }
			],
      nameRules:[
        { message:'Name should contain only alphabets.', regex:/^[a-zA-Z ]*$/ },
      ],
      emailDomain: "",
      showPassword: false,
      showConfirmPassword: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) =>
          (value && value.length >= 8) || "Min 8 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        name: (value) => {
          const pattern =
            /^[a-zA-Z ]*$/;
          return pattern.test(value) || "Name should contain only alphabets.";
        },
        password: (value) => {
          const pattern =
            /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
          return pattern.test(value) || this.passwordErrorMessage ;
        },
        confirmPassword: (value) => {
          return this.userData.password == value || this.passwordMatchError
        }
      },
      userData: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        social: 0
      },
       errors:[],
      passwordMatchError:'Password and Confirm password should match',
      passwordMatchErrorStatus:false,
    };
  },
  computed: {
    ...mapGetters(["errorSignup","errorMessageSignup"]),
  },
  created(){
    this.$store.dispatch('clearErrors');
  },
  methods: {
    setErrorSignin(){
      this.showError = false
      this.$forceUpdate()
    },
    Register() {
      this.errors = []
       const passError = this.passwordValidation();
        if (!passError.valid){
          console.log('A password must be eight characters including one uppercase letter,one lowercase letter, one special character and alphanumeric characters.');
        }
        else{
          const EMAIL = {
            ...this.userData,
            email: this.userData.email + this.emailDomain,
          };

          this.$store.dispatch("register", EMAIL);
          this.showError = true
        }
    },
      passwordValidation () {
        this.errors = []
        for (let condition of this.passwordRules) {
          if (!condition.regex.test(this.userData.password)) {
            this.errors.push(condition.message)
          }
          if (!condition.regex.test(this.userData.password_confirmation)) {
            this.errors.push(condition.message)
          }
          if(this.userData.password != this.userData.password_confirmation){
            this.errors.push(this.passwordMatchError);
            this.passwordMatchErrorStatus = true;
          }
          else{
            this.passwordMatchErrorStatus = false;
          }
        }
        for (let condition of this.nameRules) {
          if (!condition.regex.test(this.userData.name)) {
            this.errors.push(condition.message)
          }
        }
        if (this.errors.length === 0) {
          return { valid:true }
        } else {
          return { valid:false }
        }
		},
  },
};
</script>

<style>
</style>